import { initData } from '@/api/data'
import translate from 'i18n-jsautotranslate'
export default {
  data() {
    return {
      loading: false,
      data: [],
      page: 0,
      size: 10,
      total: 0,
      url: '',
      params: {},
      query: {},
      time: 170
    }
  },
  methods: {
    async init() {
      if (!await this.beforeInit()) {
        return
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        initData(this.url, this.params).then(res => {
          this.total = +res.totalElements;
          this.data = res.content;
          if(this.statistics){
            this.statistics = res.statistics;
          }
          if(this.treeData){
            if(this.params.isTree == 1){
              this.treeData = res.tree || [];
            }
          }
          setTimeout(() => {
            this.loading = false;
            var currentlang = translate.language.getCurrent();
            console.log("当前使用的是"+currentlang)
            // 获取所有具有指定class的元素
            var buttons = document.querySelectorAll('.cell .el-button');
            // 遍历这些元素
            buttons.forEach(function(button) {
                // 在该元素下查找所有的span元素
                var spans = button.getElementsByTagName('span');
                // 遍历并处理找到的span元素
                for (var i = 0; i < spans.length; i++) {
                  const translations = {
                    english: {
                        "编辑": "edit",
                        "删除": "delete",
                        "生成代码": "Generated code",
                        "执行": "Carry out",
                        "暂停": "Pause",
                        "恢复": "recover",
                        "查看": "view",
                        "客服查看": "Customer service view",
                        "审核": "examine",
                        "更多操作": "More",
                        "出入金": "Receipts and receipts",
                        "状态调整": "State adjustment",
                        "支付状态调整": "Payment status adjustment",
                        "禁用": "forbidden",
                        "启用": "enable",
                        "重置密码": "Reset password"
                    },
                    vietnamese: {
                      "编辑": "Sửa",
                      "删除": "Xóa",
                      "生成代码": "Tạo mã",
                      "执行": "Thực hiện",
                      "暂停": "Dừng",
                      "恢复": "Khôi phục",
                      "查看": "Xem",
                      "客服查看": "Xem của bộ phận chăm sóc khách hàng",
                      "审核": "Xem xét",
                      "更多操作": "Nhiều thao tác hơn",
                      "出入金": "Nhập và rút tiền",
                      "状态调整": "Điều chỉnh trạng thái",
                      "支付状态调整": "Điều chỉnh tình trạng thanh toán",
                      "禁用": "Không thể sử dụng",
                      "启用": "Bật",
                      "重置密码": "Đặt lại mật khẩu"
                    },
                    chinese_traditional:{
                      "编辑": "編輯",
                      "删除": "刪除",
                      "生成代码": "產生代碼",
                      "执行": "執行",
                      "暂停": "暫停",
                      "恢复": "恢復",
                      "查看": "查看",
                      "客服查看": "客服查看",
                      "审核": "審核",
                      "更多操作": "更多操作",
                      "出入金": "出入金",
                      "状态调整": "狀態調整",
                      "支付状态调整": "支付狀態調整",
                      "禁用": "禁用",
                      "启用": "啟用",
                      "重置密码": "重置密碼"
                  }
                    // 可以在这里添加其他语言的翻译映射
                };
                
                function translateText(text, lang) {
                    if (translations[lang] && translations[lang][text]) {
                        return translations[lang][text];
                    }
                    return text; // 如果找不到翻译，返回原文本
                }
                
                if (currentlang != "chinese_simplified") {
                  for (let i = 0; i < spans.length; i++) {
                    spans[i].innerText = translateText(spans[i].innerText.trim(), currentlang);
                  }
                }
                }
            });
          }, 1000)
          resolve(res)
        }).catch(err => {
          this.loading = false
          reject(err)
        })
      })
    },
    beforeInit() {
      return true
    },
    pageChange(e) {
      this.page = e - 1;
      this.init();
    },
    sizeChange(e) {
      this.page = 0
      this.size = e;
      this.init();
    },
    toQuery(query){
      this.page = 0;
      this.init();
    },
    // 排序
    sortChange(column){
      this.sort = `${column.prop},${column.order == 'ascending' ? 'asc' : 'desc' }`;
      this.toQuery();
    }
  }
}
